import React from "react"
import Layout from "../components/layout"
import TopHead from "../components/top-head"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Img from "gatsby-image"

const NumberCards = ({ number, heading, text, text2, text3 }) => (
  <>
    <div className="z-20 relative text-white font-light flex flex-row justify-start items-center w-full md:mt-10">
      <div className="py-1 w-8 bg-white text-blue-400 text-center mb-6">
        <h1 className="-mt-1 text-3xl font-normal">{number}</h1>
        <div className="h-px2 absolute w-full bg-white mt-px2"></div>
      </div>
      <h1 className="md:ml-6 ml-3 text-left text-xl sm:text-2xl mb-6">{heading}</h1>
    </div>
    <div className="mb-12 m-auto leading-normal font-light w-full text-white text-lg text-left whitespace-pre-line">
      <p className="md:mb-8 mb-5">{text}</p>
      <p className="md:mb-8 mb-5">{text2}</p>
      <p className="">{text3}</p>
    </div>
  </>
)

const UnserKonzept = (props) => (
  <>
    <Helmet title="Unser Konzept | IRT" defer={false} />
    <Layout>
      <TopHead photo={props.data.imageOne.childImageSharp.fluid} title="Das IRT Konzept" para="Das Konzept der IRT ist einfach (aber) effektiv." />
      <section className="w-full bg-white flex items-center justify-center shadow-xl md:mt-5">
        <div className="lg:w-11/12 w-full max-w-6xl p-2 lg:p-6 flex flex-col sm:flex-row justify-center items-center text-blue-400 font-light text-center">
          <div className="lg:text-2xl text-lg sm:mr-8 flex flex-row sm:flex-col items-start">
            <h1 className="whitespace-no-wrap mr-3 sm:m-0">Unsere Stärke,</h1>
            <h1>Ihr Vorteil</h1>
          </div>
          <div className="h-16 w-px2 bg-blue-400 mr-8 sm:block hidden"></div>
          <div className="my-3 h-px2 w-3/12 bg-blue-400 sm:hidden"></div>
          <h1 className="lg:text-3xl sm:text-2xl text-xl font-normal sm:w-auto">Eine individuelle Trainingstherapie</h1>
        </div>
      </section>
      <section className="m-auto text-center mt-10 md:mt-5 w-11/12 md:w-10/12 lg:w-8/12 max-w-5xl">
        <NumberCards number="1" heading="Ärztliche Untersuchung" text="Eine ärztliche Untersuchung stellt sicher, dass die MTT die richtige Maßnahme für Ihr Rücken- und Nackenschmerzproblem ist. Die meisten Patienten können das Programm vollständig absolvieren." />
        <NumberCards number="2" heading="Eingangsanalyse" text="Auf Basis der Eingangsanalyse der Rückenmuskulatur (inklusive Kraftanalyse) schneiden unsere qualifizierte und zertifizierten Therapeuten die Inhalte der Trainingstherapie exakt auf Ihre individuellen Bedürfnisse zu." />
        <NumberCards number="3" heading="Individuelle Trainingstherapie" text="Während der gesamten Therapie sind die Therapeuten stets an Ihrer Seite. Sie erhalten ein personalisiertes Training. Eine ärztliche und physiotherapeutische Begleitung ist gewährleistet." text2="Die medizinische Trainingstherapie (18 Therapieeinheiten) erfolgt mit 2 Einheiten (30 Minuten) wöchentlich an speziellen Gerätesystemen. Funktionsgymnastik und sensomotorische Übungen (Imoove) ergänzen die Behandlung." text3="Nach der 9. und nach Beendigung der Trainingstherapie folgt eine erneute Kraftanalyse, die die Wirksamkeit der Therapie ermittelt. Um den erreichten Muskelaufbau langfristig zu erhalten und Ihre Schmerzen nachhaltig zu lindern, empfehlen wir im Anschluss ein Erhaltungstraining 1- bis 2-mal im Monat. " />
      </section>
      <section className="w-11/12 md:w-10/12 lg:w-8/12 max-w-5xl lg:mb-24 mb-14 flex sm:flex-row flex-col gap-y-12 sm:gap-x-12 mb-12">
        <div className="h-full w-full border-white border-2 shadow-2xl">
          <div class="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.youtube.com/embed/yQEvcIz1M5c"
              frameBorder={0}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="h-full w-full border-white border-2 shadow-2xl">
          <div class="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.youtube.com/embed/Sgdulbmrch0"
              frameBorder={0}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </section>
      <section className="w-full bg-white flex items-center justify-center shadow-xl md:mt-5">
        <div className="md:py-2 py-4 flex flex-col sm:flex-row justify-start items-center text-blue-400 font-light text-center w-11/12 md:w-10/12 lg:w-8/12 max-w-5xl">
          <div className="h-16 w-px2 bg-blue-400 mr-8 sm:block hidden"></div>
          <h1 className="lg:text-3xl text-2xl font-normal sm:w-auto whitespace-nowrap">Unser Partner:</h1>
          <div className="md:w-1/2 w-3/4 lg:ml-20 flex justify-center ml-6 sm:my-0 my-4">
            <Img fluid={props.data.logo.childImageSharp.fluid} className="w-full" />
          </div>
        </div>
      </section>
      <section className="m-auto mt-10 md:mt-5 w-11/12 md:w-10/12 lg:w-8/12 max-w-5xl">
        <div className="mb-12 m-auto leading-normal font-light w-full text-white text-lg text-left whitespace-pre-line">
          <p className="md:mb-8 mb-5"><a className="underline" href="https://power-spine.com/">POWERSPINE</a> ist unser Partner, der die Rücken- und Nackenmaschinen herstellt. POWERSPINE hat weitere nationale und internationale Partner, die Ihnen das Training außerhalb unserer Standorte ermöglichen. </p>
        </div>
      </section>
      <section className="w-full bg-white flex items-center justify-center shadow-xl md:mt-5">
        <div className="md:py-2 py-4 flex flex-col sm:flex-row justify-start items-center text-blue-400 font-light text-center w-11/12 md:w-10/12 lg:w-8/12 max-w-5xl">
          <div className="h-16 w-px2 bg-blue-400 mr-8 sm:block hidden"></div>
          <h1 className="lg:text-3xl text-2xl font-normal sm:w-auto whitespace-nowrap">Anatomie und Wissenschaft</h1>
        </div>
      </section>
      <section className="m-auto mt-10 md:mt-5 w-11/12 md:w-10/12 lg:w-8/12 max-w-5xl">
        <div className="mb-12 m-auto leading-normal font-light w-full text-white text-lg text-left whitespace-pre-line">
          <p className="md:mb-8 mb-5">Unser Partner POWERSPINE erklärt Ihnen <a className="underline" href="https://power-spine.com/anatomieundwissenschaft/">hier</a> die Anatomie und Wissenschaft zu den POWERSPINE Geräten und dem Training.</p>
        </div>
      </section>
    </Layout>
  </>
)

export default UnserKonzept

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "photo1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo: file(relativePath: { eq: "powerspine.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
